import { routerRedux } from 'dva';
import React from 'react';
import PageLoading from 'components/Loading/PageLoading';
import { message } from 'antd'
import $$ from 'cmn-utils';
const Message = message

const apiUrl = process.env.REACT_APP_API_URL;


export default {
  htmlTitle: 'IDT - {title}',
  request: {
    prefix: apiUrl,
    withHeaders: () => ({
      Authorization: localStorage.getItem('token') ? localStorage.getItem('token') : '',
      'X-System-Code': 'system',
      'X-User-Type': 'user',
    }),
    afterResponse: response => {
      if (typeof response.success !== 'undefined') {
        response.status = response.success;
      }
      if (typeof response.success !== 'undefined') {
        response.message = response.msg;
      }
      const { success, message } = response;
      if (!success) {
        Message.error(message);
      }
      return response;
    },
    errorHandle: err => {
      if (err.code === 401) {
        const { pathname, href } = window.location
        if (pathname.indexOf('/sign/login') === -1) {
          const url = '/sign/login?redirect=' + encodeURIComponent(href);
          $$.removeStore('user');
          localStorage.removeItem('token');
          window.location.href = url;
        }
      } else {
        Message.error(err.text || err.message);
      }
    }
  },
  // 全局异常
  exception: {
    global: (err, dispatch) => {
      // console.log('error',JSON.stringify(err))
      const errName = err.name;
      // RequestError为拦截请求异常
      if (errName === 'RequestError') {
        if (err.code === 401) {
          console.log(401)
          // window.location.href = window.location.origin + '/sign/login'
          routerRedux.replace('/sign/login');
        } else {
          Message.error(err.text || err.message);
        }
      } else {
        console.error(err || err.message);
      }
    },
  },
  // 分页助手
  pageHelper: {
    // 格式化要发送到后端的数据
    requestFormat: pageInfo => {
      const { pageNum, pageSize, filters, sorts } = pageInfo;
      return {
        pageNum: pageNum,
        pageSize: pageSize,
        sorts: sorts,
        filters: filters
      };
    },
    // 格式化从后端反回的数据
    responseFormat: resp => {
      const {
        current,
        size,
        total,
        records,
        totalPage
      } = resp.data;
      return {
        pageNum: current,
        pageSize: size,
        total: total,
        totalPages: totalPage,
        list: records
      };
    }
  },
  // 路由加载效果
  router: {
    loading: <PageLoading loading />
  }
};
